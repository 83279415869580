.container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-image: url(../../../images/background.jpg);
  background-size: cover;
  z-index: 5;
}

.container-with-bg {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.menu {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-icon {
  height: 3rem;
  width: 3rem;
  padding: 0 2rem;
}
