.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
}

.container-with-bg {
  background-image: url(../../images/background.jpg);
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
}

.white-bg {
  background-color: var(--white);
}

.back {
  position: absolute;
  left: 0;
  width: 30%;
  height: 140%;
  z-index: -1;
  transition: all 1s;
}

.sides {
  height: 100%;
  width: 10rem;
  transition: all 1s;
}

.middle {
  height: 100%;
  flex-grow: 1;
  transition: all 1s;
}

/* MEDIA QUERIES */
/* 1300 */
@media (max-width: 81.25em) {
  .sides {
    width: 2rem;
  }
}
/* 1200 */
@media (max-width: 75em) {
  .sides {
    width: 1rem;
    height: 8rem;
  }
}
