.container {
  height: 100%;
  width: 22rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.5s;
}

.container-home {
  height: 100%;
  width: 22rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.5s;
}

.text {
  z-index: 10;
  font-family: "Quicksand", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.3rem;
}

.text-home {
  z-index: 10;
  font-family: "Quicksand", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.3rem;
}

.inactive {
  background-color: var(--transparent);
  color: #444;
  transition: all 1s ease;
  animation: home 1s ease;
}

.inactive:hover {
  color: #666;
}

.inactive-home {
  background-color: var(--white);
  color: var(--color-main-hover);
  transition: all 1s ease;
  animation: bg-home 1s ease;
}

.inactive-home:hover {
  color: var(--color-main);
}

.active {
  position: relative;
  overflow: hidden;
  color: var(--color-main-hover);
  animation: color-change 2s ease;
  transition: all 1s ease;
}

.active::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--white-t);
  z-index: 5;
  transform: translateX(0%);
  animation: slide 2s ease;
  transition: all 1s ease;
}

.active-home {
  position: relative;
  overflow: hidden;
  color: var(--white);
  animation: color-change-home 2s ease;
  transition: all 1s ease;
}

.active-home::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--white);
  z-index: 5;
  transform: translateX(-100%);
  animation: slide-home 2s ease;
  transition: all 1s ease;
}

@keyframes bg-home {
  0% {
    background-color: var(--transparent);
  }
  100% {
    background-color: var(--white);
  }
}

@keyframes home {
  0% {
    background-color: var(--white);
  }
  100% {
    background-color: var(--transparent);
  }
}

@keyframes color-change {
  0% {
    color: #333;
  }
  100% {
    color: var(--color-main-hover);
  }
}

@keyframes color-change-home {
  0% {
    color: var(--color-main);
  }
  100% {
    color: var(--white-t);
  }
}

@keyframes slide-home {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* MEDIA QUERIES */
/* 1700 */
@media (max-width: 106.35em) {
  .container {
    width: 18rem;
  }
  .container-home {
    width: 18rem;
  }
}
/* 1500 */
@media (max-width: 93.75em) {
  .text {
    font-size: 1.8rem;
  }
  .text-home {
    font-size: 1.8rem;
  }
}
/* 1000 */
@media (max-width: 62.5em) {
  .text {
    font-size: 1.6rem;
  }
  .text-home {
    font-size: 1.6rem;
  }
  .container {
    width: 16rem;
  }
  .container-home {
    width: 16rem;
  }
}
