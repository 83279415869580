.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #333333c5;
  backdrop-filter: blur(0.4rem);
  z-index: 99;
}

.modal {
  z-index: 100;
}
