.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  animation: pop-up 0.6s ease;
}

.btns {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}

.btn {
  padding: 1rem 4rem;
  font-family: "Bebas Neue", sans-serif;
  font-size: 2rem;
  color: var(--color-main-hover);
  background-color: var(--white);
  border: 0.2rem solid var(--color-main-hover);
  cursor: pointer;
  transition: all 0.5s;
}

.btn:hover {
  background-color: var(--color-main);
  color: black;
}

.images {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  animation: fade-in 1s ease;
}

.img {
  width: auto;
  height: 70rem;
  opacity: 0;
  object-fit: cover;
  object-position: center;
  transition: opacity 0.5s ease-in;
  z-index: 5;
}

.img-lazy {
  position: absolute;
  width: auto;
  object-fit: cover;
  object-position: center;
  height: 70rem;
  filter: blur(6px);
}

@keyframes pop-up {
  0% {
    transform: scale(0%);
  }
  100% {
    transform: scale(100%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .img {
    height: 60rem;
  }
  .img-lazy {
    height: 60rem;
  }
}
/* 1000 */
@media (max-width: 62.5em) {
  .img {
    height: 55rem;
  }
  .img-lazy {
    height: 55rem;
  }
}
/* 900 */
@media (max-width: 56.25em) {
  .img {
    height: 45rem;
  }
  .img-lazy {
    height: 45rem;
  }
}
/* 700 */
@media (max-width: 43.75em) {
  .img {
    height: 35rem;
  }
  .img-lazy {
    height: 35rem;
  }
}
/* 550 */
@media (max-width: 34.4em) {
  .img {
    height: 30rem;
  }
  .img-lazy {
    height: 30rem;
  }
}
/* 450 */
@media (max-width: 28.15em) {
  .img {
    height: 25rem;
    height: auto;
    width: 100%;
  }
  .img-lazy {
    height: 25rem;
    height: auto;
    width: 100%;
  }
}
