.container {
  width: 50%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.carousel-slider-btns {
  position: absolute;
  margin-bottom: 2rem;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  z-index: 30;
}

.carousel-slider-btn {
  width: 5rem;
  height: 5rem;
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2rem;
  transition: all 0.5s;
}

.carousel-slider-btn:hover {
  color: #333;
}

.carousel-btn {
  width: 70%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left {
  left: 0rem;
}

.right {
  right: 0rem;
}

/* MEDIA QUERIES */
/* 1600 */
@media (max-width: 100em) {
  .container {
    height: 100%;
  }
}
/* 1300 */
@media (max-width: 81.25em) {
  .container {
    width: 60%;
  }
}
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    width: 70%;
  }
}
/* 900 */
@media (max-width: 56.25em) {
  .container {
    width: 80%;
  }
}
/* 700 */
@media (max-width: 43.75em) {
  .container {
    width: 100%;
  }
}
/* 550 */
@media (max-width: 34.4em) {
  .carousel-slider-btns {
    bottom: 0rem;
  }
  .left {
    left: 10rem;
  }
  .right {
    right: 10rem;
  }
}
/* 500 */
@media (max-width: 31.25em) {
  .carousel-slider-btns {
    bottom: 1rem;
  }

  .carousel-slider-btn {
    width: 4rem;
    height: 4rem;
  }

  .carousel-slider-btn:hover {
    color: #a0a0a0;
    color: var(--white);
  }

  .left {
    left: 10rem;
  }

  .right {
    right: 10rem;
  }
}
/* 450 */
@media (max-width: 28.15em) {
  .carousel-slider-btns {
    bottom: 2rem;
  }

  .left {
    left: 15rem;
  }

  .right {
    right: 15rem;
  }
}
/* 400 */
@media (max-width: 25em) {
  .carousel-slider-btns {
    bottom: 3rem;
  }
  .left {
    left: 10rem;
  }

  .right {
    right: 10rem;
  }

  .carousel-btn {
    width: 90%;
    height: 90%;
  }
}
/* 360 */
@media (max-width: 22.5em) {
  .carousel-slider-btns {
    bottom: 5rem;
  }
}
/* 300 */
@media (max-width: 18.5em) {
  .left {
    left: 5rem;
  }
  .right {
    right: 5rem;
  }
}
