.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-orange {
  height: 20rem;
  width: 20rem;
  border-radius: 50%;
  background-color: var(--color-main);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-filter: opacity(0.5) drop-shadow(0 0 0 var(--white));
  filter: opacity(0.5) drop-shadow(0 0 0 var(--white));
  overflow: hidden;
}

.container-green {
  position: absolute;
  height: 20rem;
  width: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 100%;
  width: auto;
  padding: 1rem 0;
  transition: all 0.3s;
  animation: bouncing 3s infinite;
}

.green {
  -webkit-filter: opacity(0.3) drop-shadow(0 0 0 var(--white));
  filter: opacity(0.3) drop-shadow(0 0 0 var(--white));
}

@keyframes bouncing {
  0% {
    transform: translateY(0%) rotate(0);
  }
  20% {
    transform: translateY(-30%) rotate(0deg);
  }
  40% {
    transform: translateY(-30%) rotate(-20deg);
  }
  45% {
    transform: translateY(-30%) rotate(20deg);
  }
  50% {
    transform: translateY(-30%) rotate(-20deg);
  }
  55% {
    transform: translateY(-30%) rotate(20deg);
  }
  60% {
    transform: translateY(-30%) rotate(-20deg);
  }
  80% {
    transform: translateY(-30%) rotate(0deg);
  }
  0% {
    transform: translateY(0%) rotate(0deg);
  }
}

/* MEDIA QUERIES */
/* 1600 */
@media (max-width: 100em) {
}
/* 1300 */
@media (max-width: 81.25em) {
}
/* 1200 */
@media (max-width: 75em) {
}
/* 900 */
@media (max-width: 56.25em) {
}
/* 800 */
@media (max-width: 50em) {
}
/* 700 */
@media (max-width: 43.75em) {
}
/* 600 */
@media (max-width: 37.5em) {
}
/* 500 */
@media (max-width: 31.25em) {
}
/* 450 */
@media (max-width: 28.15em) {
}
/* 360 */
@media (max-width: 22.5em) {
}
/* 300 */
@media (max-width: 18.75em) {
}
