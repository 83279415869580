.container {
  height: 100vh;
  width: 100vw;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.header {
  width: 100%;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 30;
  transition: all 1s;
}

.header-small-screen {
  width: 100%;
  height: 8rem;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 30;
  transition: all 1s;
}

.carousel {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(./images/background.jpg);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 25;
}

.body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@keyframes move-up {
  0% {
    bottom: 8rem;
  }

  100% {
    top: 0rem;
  }
}

@keyframes move-down {
  0% {
    top: 0rem;
  }

  100% {
    bottom: 8rem;
  }
}

/* MEDIA QUERIES */
/* 1200 */
@media (max-width: 75em) {
  .header {
    height: 8rem;
  }
}
/* 900 */
@media (max-width: 56.25em) {
  .header {
    display: none;
  }
  .header-small-screen {
    display: flex;
  }
}
