.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 20rem;
  transition: all 1s;
}

.inner-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* MEDIA QUERIES */
/* 1200 */
@media (max-width: 75em) {
  .container {
    padding-bottom: 15rem;
  }
}
