.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.link {
  width: 100%;
  padding: 1.4rem 2rem 1.4rem 0;
  font-family: "Quicksand", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--grey);
  transition: all 0.5s;
}

.link:hover {
  background-color: var(--grey);
  color: var(--white);
}
