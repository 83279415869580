.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  z-index: 5;
}

.menu-icon {
  border-radius: 50%;
  /* border: 0.2rem solid var(--grey); */
  height: 4rem;
  width: 4rem;
  padding: 0 0.4rem;
  color: var(--grey);
  transition: all 0.5s;
}

.menu-icon:hover {
  color: #999;
  border-color: #999;
}
