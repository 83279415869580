.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../images/background.jpg);
  background-size: cover;
  filter: grayscale(1);
  animation: fade-in 1s ease;
}

.text {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Josefin Sans", sans-serif;
  color: #888;
  background-color: #fff;
  padding: 2rem 5rem;
  border-radius: 1rem;
}

.text p {
  font-size: 5rem;
  font-weight: 400;
}

.text span {
  font-size: 8rem;
  font-weight: 500;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* MEDIA QUERIES */
/* 1300 */
@media (max-width: 81.25em) {
  .text p {
    font-size: 3.2rem;
  }

  .text span {
    font-size: 5rem;
  }
}
/* 300 */
@media (max-width: 18.75em) {
  .text {
    padding: 2rem 2.4rem;
  }
}
