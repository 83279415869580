.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  gap: 1rem;
  position: absolute;
  right: 0;
}

.link {
  height: 3rem;
  width: 4rem;
  padding-right: 1rem;
  border: none;
  border-color: var(--color-main);
  transition: all 0.3s;
}

.link:hover {
  border-right: 0.6rem solid var(--white);
  width: 5rem;
}

.icon {
  height: 3rem;
  width: 3rem;
  color: var(--white);
}

.github {
  color: black;
}

/* MEDIA QUERIES */
/* 700 */
@media (max-width: 43.75em) {
  .container {
    right: auto;
    align-items: flex-start;
    flex-direction: row;
  }

  .link {
    padding-top: 1rem;
    padding-right: 0rem;
    margin-top: 0rem;
    width: 3rem;
  }

  .link:hover {
    border-right: none;
    border-top: 0.6rem solid var(--white);
    width: 3rem;
  }
}
