.container {
  width: 30%;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  animation: enter-left 0.5s ease;
}

.inner-container {
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

@keyframes enter-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

/* MEDIA QUERIES */
/* 900 */
@media (max-width: 56.25em) {
  .container {
    width: 40%;
  }
}
/* 450 */
@media (max-width: 28.15em) {
  .container {
    width: 60%;
  }
}
